<template>
  <Modal :value="visible"
         :title="dataId ? '修改' + title : '新增' + title"
         @on-visible-change="onChangeVisible"
         width="600">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>所属机构：</p>
          <MyTreeSelect :value="departmentName"
                        placeholder="所属机构"
                        :data="departmentArr"
                        search
                        style="width: 100%"
                        @onSelectChange="onChangeDepartment">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>用电量：</p>
          <Input v-model="quantity"
                 maxlength="30"
                 v-floatLimit
                 placeholder="用水量"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">单价：</p>
          <Input v-model="price"
                 maxlength="30"
                 v-floatLimit
                 clearable
                 placeholder="单价"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>总费用：</p>
          <Input v-model="totalPrice"
                 maxlength="30"
                 v-floatLimit
                 placeholder="总费用"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>缴费时间：</p>
          <DatePicker :value="time"
                      type="date"
                      placeholder="加油日期"
                      style="width: 100%"
                      @on-change="onChangeTime"></DatePicker>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">缴费人：</p>
          <MyTreeSelect :value="payPersonName"
                        placeholder="缴费人"
                        readonly
                        clearable
                        :data="payPersonArr"
                        search
                        @onSelectChange="onChangeElectricityPayPerson">
          </MyTreeSelect>
        </li>
        <!-- <li class="form-ul-li half">
          <p class="li-title">服务商：</p>
          <Select v-model="servicer"
                  placeholder="服务商">
            <Option :value="item.id"
                    v-for="item in servicerArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li> -->
        <li class="form-ul-li half">
          <p class="li-title">地址：</p>
          <Input v-model="address"
                 readonly
                 placeholder="地址">
          <Icon custom="i-icon icon-weizhishi"
                size="20"
                color="#25bb96"
                class="cursor-pointer"
                slot="suffix"
                @click.stop="onClickSite" />
          </Input>
        </li>
        <li class="form-ul-li">
          <p class="li-title">附件：</p>
          <ul class="file-img">
            <UploadFile @uploadSuccess="uploadPicture"
                        :disabled="picture"
                        accept=".doc,image/*">
              <li>
                <el-image :src="picture"
                          fit="cover"
                          :preview-src-list="[picture]"
                          class="w-full h-full"
                          v-show="picture && getFileType(picture) == 1">
                </el-image>
                <img src="../../../../../assets/common/word.png"
                     v-show="picture && getFileType(picture) == 2"
                     @click.stop="onClickDownFile(picture)">
                <Icon type="md-close-circle"
                      size="24"
                      color="#fa5357"
                      class="file-img-deleteIcon"
                      @click.stop="onClickDeletePicture"
                      v-show="picture" />
                <Icon custom="i-icon icon-tianjia1"
                      size="40"
                      color="#B5B5B5"
                      v-show="!picture" />
              </li>
            </UploadFile>
          </ul>
        </li>
        <li class="form-ul-li">
          <p class="li-title"></p>
          <div class="file-tip">
            支持格式为png、jpg、jpeg、doc，最大不能超过20M；
          </div>
        </li>
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>

    <!-- 选择地址 -->
    <PositionModal v-model="positionVisible"
                   :lng="lng"
                   :lat="lat"
                   @onClickConfirm="onChangeSite" />
  </Modal>
</template>

<script>
import UploadFile from '@/components/common/UploadFile';
import MyTreeSelect from '../../../../common/MyTreeSelect.vue'
import PositionModal from '@/components/product/main/template/PositionModal'
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {
    UploadFile,
    MyTreeSelect,
    PositionModal
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    title: {
      type: String,
      default: '电费'
    },
    visible: Boolean,
    dataId: String
  },
  data () {
    return {
      loading: false,
      departmentName: '',
      departmentId: '',
      quantity: '',
      price: '',
      totalPrice: '',
      time: '',
      payPersonName: '',
      payPersonId: '',
      servicer: '',
      picture: '',
      remark: '',
      servicerArr: [],
      // 选择地址
      positionVisible: false,
      lng: '',
      lat: '',
      address: '',
    }
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    },
    visible (newVal) {
      this.payPersonName = this.$store.getters.getUserName
      this.payPersonId = this.$store.getters.getUserId
    }
  },
  computed: {
    ...mapGetters({
      'departmentArr': 'getDepartmentTreeList',
      'payPersonArr': 'getUserTreeList'
    })
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateDepartmentTreeList',
      'updateUserTreeList'
    ]),
    init () {
      // operatingCost已调用，防重复调用
      // this.updateDepartmentTreeList()
      // OperatingCostWaterModal已调用，防重复调用
      // this.updateUserTreeList()
    },
    getDetail () {
      this.$store.dispatch('getOperatingCostDetail', this.dataId).then(res => {
        for (let i in res) {
          this[i] = res[i]
        }
      })
    },
    getFileType (file) {
      let filePath = file
      let index = filePath.lastIndexOf(".");
      let type = filePath.substr(index + 1);
      let imgArr = ['tif', 'pjp', 'webp', 'xbm', 'jxl', 'svgz', 'jpg', 'jpeg', 'ico', 'tiff', 'gif', 'svg', 'bmp', 'png', 'jfif', 'pjpeg', 'avif']
      if (imgArr.includes(type)) {
        return 1
      } else {
        return 2
      }
    },
    // 点击下载文件
    onClickDownFile (file) {
      window.open(file)
    },
    // 修改所属机构
    onChangeDepartment (section) {
      this.departmentId = section.id
      this.departmentName = section.name
    },
    // 选择缴费人
    onChangeElectricityPayPerson (section) {
      if (section.type != 'depart') {
        this.payPersonName = section.name
        this.payPersonId = section.id
      }
    },
    // 选择时间
    onChangeTime (date) {
      this.time = date
    },
    // 点击选择地址
    onClickSite () {
      this.positionVisible = true
    },
    // 上传照片
    uploadPicture (arr) {
      if (arr.length > 0) {
        this.picture = arr[0].url
      }
    },
    // 删除图片
    onClickDeletePicture () {
      this.picture = ''
    },
    // 修改地址
    onChangeSite (e) {
      this.address = e.address
      this.lng = e.lng
      this.lat = e.lat
    },
    // 确定按钮
    onClickConfirm () {
      if (!this.departmentId) return this.$Message.info('请选择所属机构');
      if (!this.quantity) return this.$Message.info('请输入用水量');
      if (!this.totalPrice) return this.$Message.info('请输入总费用');
      if (!this.time) return this.$Message.info('请选择缴费时间');
      let params = {
        departmentId: this.departmentId,
        type: '电费',
        quantity: this.quantity,
        price: this.price,
        totalPrice: this.totalPrice,
        time: this.time,
        payPersonId: this.payPersonId,
        servicer: this.servicer,
        lngLat: `${this.lng},${this.lat}`,
        address: this.address,
        picture: this.picture,
        remark: this.remark,
      }
      if (this.dataId) params.id = this.dataId
      this.loading = true
      this.$store.dispatch('createOrEditOperatingCost', params).then(res => {
        this.$emit('onClickConfirm', params)
        this.$emit('onChange', false)
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
        this.resetData()
      }
    },
    resetData () {
      this.departmentName = ''
      this.departmentId = ''
      this.quantity = ''
      this.price = ''
      this.totalPrice = ''
      this.time = ''
      this.payPersonName = this.$store.getters.getUserName
      this.payPersonId = this.$store.getters.getUserId
      this.servicer = ''
      this.address = ''
      this.lng = ''
      this.lat = ''
      this.picture = ''
      this.remark = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-cont {
  max-height: none;
}
</style>