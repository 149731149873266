<template>
  <div class="main">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
      </Tabs>
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex a-center">
            <MyTreeSelect :value="departmentName"
                          placeholder="所属机构"
                          clearable
                          :data="departmentTreeArr"
                          search
                          class="m-r-10"
                          style="width:160px"
                          @onSelectChange="onChangeDepartment">
            </MyTreeSelect>
            <template v-if="currentTabIndex===2">
              <Select v-model="costType"
                      placeholder="费用类型"
                      clearable
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in costTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.costname }}</Option>
              </Select>
            </template>
            <!-- <DatePicker :value="date"
                        type="daterange"
                        :start-date="new Date()"
                        placeholder="请选择时间范围"
                        @on-change="onChangeDate"
                        class="m-r-10"
                        style="width:260px"></DatePicker> -->
          </div>
          <div class="flex">
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/operatingCost/query'])"
                    @click.stop="onClickSearch">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10"
                    v-if="checkPermi(['/admin/operatingCost/query'])"
                    @click.stop="onClickReset">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="flex">
        <Button type="primary"
                class="m-r-10"
                v-if="checkPermi(['/admin/operatingCost/add'])"
                @click.stop="onClickAddModal">
          <Icon custom="i-icon icon-tianjia1"
                size="16"></Icon>
          添加
        </Button>
        <Dropdown v-if="checkPermi(['/admin/operatingCost/export'])">
          <Button class="m-r-10">
            <Icon custom="i-icon icon-shangchuan"
                  size="16"></Icon>
            导出
          </Button>
          <DropdownMenu slot="list">
            <DropdownItem name="part">
              <ExportTemplate :path="getExportPath"
                              :data="getExportData('part')">
                <Button>
                  导出部分
                </Button>
              </ExportTemplate>
            </DropdownItem>
            <DropdownItem name="now">
              <ExportTemplate :path="getExportPath"
                              :data="getExportData('now')">
                <Button>
                  导出本页
                </Button>
              </ExportTemplate>
            </DropdownItem>
            <DropdownItem name="all">
              <ExportTemplate :path="getExportPath"
                              :data="getExportData('all')">
                <Button>
                  导出全部
                </Button>
              </ExportTemplate>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <ExportTemplate :path="getExportTemplatePath"
                        :data="getExportTemplateData"
                        v-if="checkPermi(['/admin/operatingCost/export'])">
          <Button class="m-r-10">
            <Icon custom="i-icon icon-shangchuan"
                  size="16"></Icon>
            导出模板
          </Button>
        </ExportTemplate>
        <ReaderFile @uploadSuccess="onClickReader"
                    accept=".xls,.xlsx"
                    :action='getReaderFileAction'
                    :type="getReaderFileType"
                    v-if="checkPermi(['/admin/operatingCost/import'])">
          <Button class="m-r-10">
            <Icon custom="i-icon icon-xiazai"
                  size="16"></Icon>
            导入
          </Button>
        </ReaderFile>
        <Dropdown @on-click="onClickBatch"
                  v-if="checkPermi(['/admin/operatingCost/delete'])">
          <Button class="m-r-10">
            <Icon custom="i-icon icon-xiugai1"
                  size="16"></Icon>
            批量操作
          </Button>
          <DropdownMenu slot="list">
            <DropdownItem name="delete"
                          v-if="checkPermi(['/admin/operatingCost/delete'])">批量删除</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
      <div class="table">
        <Table ref="table"
               :height="tableHeight"
               :columns="getColumns"
               :data="data"
               border
               stripe
               :loading="tableLoading"
               :row-class-name="rowClassName"
               @on-selection-change="onChangeSelect"
               @on-sort-change="onChangeSort">
          <template slot-scope="{ row }"
                    slot="operation">
            <template v-if="currentTabIndex===0">
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/operatingCost/edit']"
                      @click.stop="onClickEditWater(row)">修改</Button>
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/operatingCost/delete']"
                      @click.stop="onClickDeleteOperatingCost(row)">删除</Button>
            </template>
            <template v-if="currentTabIndex===1">
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/operatingCost/edit']"
                      @click.stop="onClickEditElectricity(row)">修改</Button>
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/operatingCost/delete']"
                      @click.stop="onClickDeleteOperatingCost(row)">删除</Button>
            </template>
            <template v-if="currentTabIndex===2">
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/operatingCost/edit']"
                      @click.stop="onClickEditOther(row)">修改</Button>
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/operatingCost/delete']"
                      @click.stop="onClickDeleteOperatingCost(row)">删除</Button>
            </template>
          </template>
        </Table>
        <Page class="m-t-10 m-l-10"
              :total="totalPage"
              :current="pageNum"
              :page-size="pageSize"
              show-total
              show-sizer
              show-elevator
              @on-change="onPageChange"
              @on-page-size-change="onPageSizeChange" />
      </div>
    </div>

    <!-- 水费 -->
    <OperatingCostWaterModal v-model="operatingCostWaterVisible"
                             :dataId.sync="operatingCostWaterId"
                             @onClickConfirm="getList" @onChange='onChangClearRowStyle'></OperatingCostWaterModal>

    <!-- 电费 -->
    <OperatingCostElectricityModal v-model="operatingCostElectricityVisible"
                                   :dataId.sync="operatingCostElectricityId"
                                   @onClickConfirm="getList" @onChange='onChangClearRowStyle'></OperatingCostElectricityModal>

    <!-- 杂费 -->
    <OperatingCostOtherModal v-model="operatingCostOtherVisible"
                             :dataId.sync="operatingCostOtherId"
                             @onClickConfirm="getList" @onChange='onChangClearRowStyle'></OperatingCostOtherModal>
  </div>
</template>

<script>
import OperatingCostWaterModal from '@/components/product/admin/modal/cost/OperatingCostWaterModal'
import OperatingCostElectricityModal from '@/components/product/admin/modal/cost/OperatingCostElectricityModal'
import OperatingCostOtherModal from '@/components/product/admin/modal/cost/OperatingCostOtherModal'
import { mapActions, mapGetters } from 'vuex'
import MyTreeSelect from '@/components/common/MyTreeSelect';
import ExportTemplate from '@/components/common/ExportTemplate'
import ReaderFile from '@/components/common/ReaderFile'
import { checkPermi } from '@/utils/permission'
export default {
  components: {
    OperatingCostWaterModal,
    OperatingCostElectricityModal,
    OperatingCostOtherModal,
    MyTreeSelect,
    ExportTemplate,
    ReaderFile
  },
  data () {
    return {
      currentTabIndex: 0,
      tabArr: [
        {
          name: '水费'
        },
        {
          name: '电费'
        },
        {
          name: '运营杂费'
        },
      ],
      departmentName: '',
      departmentId: '',
      costType: '',
      costTypeArr: [],
      date: [],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight: 0,
      tableLoading: false,
      columnsWater: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '机构名称',
          key: 'waterdept',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '总费用(元)',
          key: 'totalcost',
          tooltip: true,
          align: 'center'
        },
        {
          title: '缴费时间',
          key: 'paytime',
          tooltip: true,
          minWidth: 80,
          align: 'center'
        },
        {
          title: '用水量(吨)',
          key: 'waterusage',
          tooltip: true,
          align: 'center'
        },
        {
          title: '单价(元/吨)',
          key: 'unit',
          tooltip: true,
          align: 'center'
        },
        {
          title: '缴费人',
          key: 'paypersonName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '服务商',
          key: 'servicevendor',
          tooltip: true,
          align: 'center'
        },
        {
          title: '地址',
          key: 'addrdetail',
          tooltip: true,
          align: 'center'
        },
        {
          title: '附件',
          key: 'fileurl',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.atta) {
              if (row.atta) {
                if (this.getFileType(row.atta) == 1) {
                  return h('el-image', {
                    props: {
                      src: row.atta,
                      'preview-src-list': [row.atta]
                    },
                    style: {
                      width: '22px',
                      height: '22px',
                      cursor: 'pointer'
                    }
                  })
                } else {
                  return h('img', {
                    domProps: {
                      src: require('../../../assets/common/word.png')
                    },
                    style: {
                      width: '22px',
                      height: '22px',
                      cursor: 'pointer'
                    },
                    on: {
                      click: () => {
                        this.onClickDownFile(row)
                      }
                    }
                  })
                }
              }
            }
            return h('span', '')
          }
        },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          minWidth: 80,
          align: 'center'
        }
      ],
      columnsElectricity: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '机构名称',
          key: 'waterdept',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '总费用(元)',
          key: 'totalcost',
          tooltip: true,
          align: 'center'
        },
        {
          title: '缴费时间',
          key: 'paytime',
          tooltip: true,
          minWidth: 80,
          align: 'center'
        },
        {
          title: '用电量(kw)',
          key: 'waterusage',
          tooltip: true,
          align: 'center'
        },
        {
          title: '单价(元/度)',
          key: 'unit',
          tooltip: true,
          align: 'center'
        },
        {
          title: '缴费人',
          key: 'paypersonName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '服务商',
          key: 'servicevendor',
          tooltip: true,
          align: 'center'
        },
        {
          title: '地址',
          key: 'addrdetail',
          tooltip: true,
          align: 'center'
        },
        {
          title: '附件',
          key: 'fileurl',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.atta) {
              if (row.atta) {
                if (this.getFileType(row.atta) == 1) {
                  return h('el-image', {
                    props: {
                      src: row.atta,
                      'preview-src-list': [row.atta]
                    },
                    style: {
                      width: '22px',
                      height: '22px',
                      cursor: 'pointer'
                    }
                  })
                } else {
                  return h('img', {
                    domProps: {
                      src: require('../../../assets/common/word.png')
                    },
                    style: {
                      width: '22px',
                      height: '22px',
                      cursor: 'pointer'
                    },
                    on: {
                      click: () => {
                        this.onClickDownFile(row)
                      }
                    }
                  })
                }
              }
            }
            return h('span', '')
          }
        },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          minWidth: 80,
          align: 'center'
        }
      ],
      columnsOther: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '机构名称',
          key: 'waterdept',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '费用(元)',
          key: 'totalcost',
          tooltip: true,
          align: 'center'
        },
        {
          title: '费用类型',
          key: 'costtypeitemName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '缴费时间',
          key: 'paytime',
          tooltip: true,
          minWidth: 80,
          align: 'center'
        },
        {
          title: '缴费人',
          key: 'paypersonName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '地址',
          key: 'addrdetail',
          tooltip: true,
          align: 'center'
        },
        {
          title: '附件',
          key: 'fileurl',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.atta) {
              if (row.atta) {
                if (this.getFileType(row.atta) == 1) {
                  return h('el-image', {
                    props: {
                      src: row.atta,
                      'preview-src-list': [row.atta]
                    },
                    style: {
                      width: '22px',
                      height: '22px',
                      cursor: 'pointer'
                    }
                  })
                } else {
                  return h('img', {
                    domProps: {
                      src: require('../../../assets/common/word.png')
                    },
                    style: {
                      width: '22px',
                      height: '22px',
                      cursor: 'pointer'
                    },
                    on: {
                      click: () => {
                        this.onClickDownFile(row)
                      }
                    }
                  })
                }
              }
            }
            return h('span', '')
          }
        },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          minWidth: 80,
          align: 'center'
        }
      ],
      data: [],
      selectArr: [],
      selectDataArr: [],
      selectIndexArr: [],
      selectVisitedArr: [],
      selectVisitedIndexArr: [],
      // 水费
      operatingCostWaterVisible: false,
      operatingCostWaterId: '',
      // 电费
      operatingCostElectricityVisible: false,
      operatingCostElectricityId: '',
      // 运营杂费
      operatingCostOtherVisible: false,
      operatingCostOtherId: ''
    };
  },
  watch: {
    currentTabIndex (newVal) {
      this.resetSearchData()
      this.onClickSearch()
      if (newVal == 2) {
        this.getDoctSmallTypeItem()
      }
    },
    selectDataArr (newVal) {
      this.selectIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          let data = this.data.find((items, index) => {
            return item.id == items.id
          })
          this.selectIndexArr.push(data.id)
        })
      }
    },
    selectVisitedArr (newVal) {
      console.log(newVal);
      this.selectVisitedIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          let data = this.data.find((items, index) => {
            return item.id == items.id
          })
          this.selectVisitedIndexArr = [data.id]
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      'departmentTreeArr': 'getDepartmentTreeList',
      'dictBigTypeList': 'getDictBigTypeList'
    }),
    getColumns () {
      let columns = []
      switch (this.currentTabIndex) {
        case 0:
          columns = this.columnsWater
          break;
        case 1:
          columns = this.columnsElectricity
          break;
        case 2:
          columns = this.columnsOther
          break;
        default:
          break;
      }
      return columns
    },
    getExportPath () {
      let path = ''
      switch (this.currentTabIndex) {
        case 0:
        case 1:
        case 2:
          path = this.$http.exportOperatingCost
          break;
        default:
          break;
      }
      return path
    },
    getExportTemplatePath () {
      let path = ''
      switch (this.currentTabIndex) {
        case 0:
        case 1:
        case 2:
          path = this.$http.exportOperatingCostTem
          break;
        default:
          break;
      }
      return path
    },
    getExportTemplateData () {
      let data = ''
      switch (this.currentTabIndex) {
        case 0:
          data = { types: '水费' }
          break;
        case 1:
          data = { types: '电费' }
          break;
        case 2:
          data = { types: '杂费' }
          break;
        default:
          break;
      }
      return data
    },
    getReaderFileAction () {
      let readerFileAction = []
      switch (this.currentTabIndex) {
        case 0:
        case 1:
        case 2:
          readerFileAction = '/hss/tlcostoperate/excelReader'
          break;
        default:
          break;
      }
      return readerFileAction
    },
    getReaderFileType () {
      let type = ''
      switch (this.currentTabIndex) {
        case 0:
          type = '水费'
          break;
        case 1:
          type = '电费'
          break;
        case 2:
          type = '杂费'
          break;
        default:
          break;
      }
      return type
    }
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    checkPermi,
    ...mapActions([
      'updateDepartmentTreeList',
      'updateDictBigTypeList'
    ]),
    init () {
      this.tableHeight = window.innerHeight - 350
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 350
      }
      this.updateDepartmentTreeList()
      this.updateDictBigTypeList()
      this.getList()
    },
    getDoctSmallTypeItem () {
      let index = this.dictBigTypeList.findIndex(item => {
        return item.costtype == '运营杂费'
      })
      this.$http.getCostDictionariesItemAllList({ id: this.dictBigTypeList[index].id }).then((res) => {
        if (res.code === 200) {
          this.costTypeArr = res.result
          console.log(this.costTypeArr);
        }
      })
    },
    // 判断文件类型
    getFileType (file) {
      let filePath = file
      let index = filePath.lastIndexOf(".");
      let type = filePath.substr(index + 1);
      let imgArr = ['tif', 'pjp', 'webp', 'xbm', 'jxl', 'svgz', 'jpg', 'jpeg', 'ico', 'tiff', 'gif', 'svg', 'bmp', 'png', 'jfif', 'pjpeg', 'avif']
      if (imgArr.includes(type)) {
        return 1
      } else {
        return 2
      }
    },
    // 点击下载文件
    onClickDownFile (row) {
      console.log(row);
      window.open(row.atta)
    },
    // 修改所属机构
    onChangeDepartment (section) {
      this.departmentId = section.id
      this.departmentName = section.name
    },
    onClickSet () {

    },
    onChangeDate (date) {
      this.date = date
    },
    onClickBatch (name) {
      switch (name) {
        case 'delete':
          switch (this.currentTabIndex) {
            case 0:
              this.onDeleteOperatingCost(this.selectArr)
              break;
            case 1:
              this.onDeleteRepair(this.selectArr)
              break;
            case 2:
              this.onDeleteMaintain(this.selectArr)
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    },
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
      this.selectDataArr = selection
    },
    onChangeSort (column, key, order) {

    },
    rowClassName (row, index) {
      if (this.selectVisitedIndexArr.includes(row.id)) {
        return 'table-visited-bgcolor'
      }
      if (this.selectIndexArr.includes(row.id)) {
        return 'table-bgcolor'
      }
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    onClickAddModal () {
      switch (this.currentTabIndex) {
        case 0:
          this.operatingCostWaterVisible = true
          break;
        case 1:
          this.operatingCostElectricityVisible = true
          break;
        case 2:
          this.operatingCostOtherVisible = true
          break;
        default:
          break;
      }
    },
    getList () {
      this.selectIndexArr = []
      let params = {
        waterdept: this.departmentId,
        costtypeitem: this.costType,
        page: this.pageNum,
        size: this.pageSize,
      }
      if (this.date[0]) {
        params.starttime = this.date[0]
        params.endtime = this.date[1]
      }
      switch (this.currentTabIndex) {
        case 0:
          params.costtype = '水费'
          break;
        case 1:
          params.costtype = '电费'
          break;
        case 2:
          params.costtype = '杂费'
          break;
        default:
          break;
      }
      this.tableLoading = true
      this.$http.getOperatingCostList(params).then((res) => {
        this.data = res.result.resultlist
        this.totalPage = res.result.total
        this.tableLoading = false
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 水费修改
    onClickEditWater (row) {
      this.selectVisitedArr.push(row)
      this.operatingCostWaterVisible = true
      this.operatingCostWaterId = row.id
    },
    // 电费修改
    onClickEditElectricity (row) {
      this.selectVisitedArr.push(row)
      this.operatingCostElectricityVisible = true
      this.operatingCostElectricityId = row.id
    },
    // 杂费修改
    onClickEditOther (row) {
      this.selectVisitedArr.push(row)
      this.operatingCostOtherVisible = true
      this.operatingCostOtherId = row.id
    },
    // 删除
    onClickDeleteOperatingCost (row) {
      this.selectVisitedArr.push(row)
      this.onDeleteOperatingCost([row.id])
    },
    // 删除
    onDeleteOperatingCost (arr) {
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            ids: arr
          }
          this.$http.deleteOperatingCost(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.onChangClearRowStyle()
              this.getList()
            }
          })
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.getList()
    },
    // 点击重置
    onClickReset () {
      this.resetSearchData()
      this.onClickSearch()
    },
    // 重置搜索条件
    resetSearchData () {
      this.departmentId = ''
      this.departmentName = ''
      this.date = []
      this.costtype = ''
    },
    onChangClearRowStyle () {
      this.selectVisitedArr = []
      
    },
    // 导出文件数据
    getExportData (value) {
      let data = {}
      switch (this.currentTabIndex) {
        case 0:
          data = {
            costType: '',
            deptid: this.departmentId,
            types: '水费'
          }
          if (this.date[0]) {
            data.startTime = this.date[0]
            data.endTime = this.date[1]
          }
          if (value == 'now') {
            data.ids = this.data.map(item => {
              return item.id
            })
          } else if (value == 'part') {
            data.ids = this.selectArr
          } else {
            // data.ids = []
          }
          break;
        case 1:
          data = {
            costType: '',
            deptid: this.departmentId,
            types: '电费'
          }
          if (this.date[0]) {
            data.startTime = this.date[0]
            data.endTime = this.date[1]
          }
          if (value == 'now') {
            data.ids = this.data.map(item => {
              return item.id
            })
          } else if (value == 'part') {
            data.ids = this.selectArr
          } else {
            // data.ids = []
          }
          break;
        case 2:
          data = {
            costType: this.costType,
            deptid: this.departmentId,
            types: '杂费'
          }
          if (this.date[0]) {
            data.startTime = this.date[0]
            data.endTime = this.date[1]
          }
          if (value == 'now') {
            data.ids = this.data.map(item => {
              return item.id
            })
          } else if (value == 'part') {
            data.ids = this.selectArr
          } else {
            // data.ids = []
          }
          break;
        default:
          break;
      }
      return data
    },
    // 导入回调
    onClickReader (arr) {
      if (arr.length > 0) {
        this.$Message.info('导入成功')
        this.getList()
      }
    },
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
::v-deep {
  .ivu-table .table-bgcolor td {
    background: #ebf7ff !important;
    border-right: 1px solid #ebf7ff;
  }
  .ivu-table .table-visited-bgcolor td {
    background: rgb(235, 247, 255) !important;
    border-right: 1px solid rgb(235, 247, 255);
  }
}
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;
  .topbar {
    background-color: #fff;
    .topbar-search {
      padding: 16px;
    }
  }
  .content {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;
    .tree {
      margin-top: 16px;
      width: 230px;
      margin-right: 16px;
      overflow-y: auto;
      flex-shrink: 0;
      border: 1px solid #e8eaec;
    }
    .table {
      margin-top: 16px;
      flex: 1;
    }
  }
}
</style>